import React from 'react'
import Layout from '../components/layout'
import { Section, Heading, Text, AboutContainer } from '../styles/default-template.styled'
import SEO from '../components/seo'

const TakkPage = () => {
    const seo = {
        description: ""
    }
    return (
        <Layout>
            <SEO title="Din henvendelse er mottatt" seo={seo} />
            <Section>
                <AboutContainer>
                    <Heading>Takk!</Heading>
                    <Text>Din henvendelse er mottatt. Vi tar kontakt så fort som mulig!</Text>
                </AboutContainer>
            </Section>
        </Layout>
    )
}

export default TakkPage